/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useState, useContext, createContext, useEffect } from "react";
import { useAuthContext } from "context/authContext";
import momentTz from "moment-timezone";

const TimeZoneContext = createContext();

const TimeZoneContextProvider = ({ children }) => {
  const { user } = useAuthContext();
  const [timeZone, setTimeZone] = useState(user?.timezoneTzName);

  const changeTimeZone = (tz) => {
    setTimeZone(tz);
  };

  const adjustToBrowserTimeZone = (d) => {
    const baseOffsetInMinutes = momentTz.tz(d, timeZone).utcOffset();
    const browserOffsetInMinutes = momentTz(d).utcOffset();
    const offsetToRemove = browserOffsetInMinutes - baseOffsetInMinutes;
    const fakeDate = new Date(d);
    fakeDate.setMinutes(fakeDate.getMinutes() - offsetToRemove);
    return fakeDate;
  };

  const adjustFromBrowserTimeZone = (d) => {
    const baseOffsetInMinutes = momentTz.tz(d, timeZone).utcOffset();
    const browserOffsetInMinutes = momentTz(d).utcOffset();
    const offsetToAdd = browserOffsetInMinutes - baseOffsetInMinutes;
    const date = new Date(d);
    date.setMinutes(date.getMinutes() + offsetToAdd);
    return date;
  };

  const formatTimestamp = (d, format) => {
    return momentTz.tz(d, timeZone).format(format);
  };

  const timeDifferenceFromNow = (d) => {
    return momentTz.tz(d, timeZone).fromNow();
  };

  const isCurrentDay = (d) => {
    return momentTz().tz(timeZone).isSame(momentTz.tz(d, timeZone), "day");
  };

  const getMomentTz = (d = new Date()) => {
    return momentTz.tz(d, timeZone);
  };

  useEffect(() => {
    setTimeZone(user?.timezoneTzName);
  }, [user]);

  const tzContext = {
    timeZone,
    changeTimeZone,
    adjustToBrowserTimeZone,
    adjustFromBrowserTimeZone,
    formatTimestamp,
    timeDifferenceFromNow,
    isCurrentDay,
    getMomentTz,
  };

  return (
    <TimeZoneContext.Provider value={tzContext}>
      {children}
    </TimeZoneContext.Provider>
  );
};

function useTimeZoneContext() {
  const context = useContext(TimeZoneContext);
  if (context === undefined) {
    throw new Error(
      "useTimeZoneContext must be used within a TimeZoneContextProvider",
    );
  }
  return context;
}

export { TimeZoneContextProvider, useTimeZoneContext };
