/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import produce from "immer";

import {
  DAYS_OF_THE_WEEK,
  DEFAULT_ADVANCED_AVAILABILITY_SETTINGS,
} from "constants/app.constants";
import { PATH } from "constants/path.constants";

export function convertToUIFormat(slots = []) {
  const daysInSlots = Array.from(new Set(slots.map(({ day }) => day)));
  let slotsDraft = DEFAULT_ADVANCED_AVAILABILITY_SETTINGS.map(
    ({ day, slots }) => {
      const slotData = { day, enabled: false, slots };
      if (daysInSlots.includes(day)) {
        slotData.slots = [];
      }
      return slotData;
    },
  );

  slots.forEach(({ day, availability, startTime, endTime }) => {
    const dayIndex = Object.values(DAYS_OF_THE_WEEK).findIndex(
      (item) => item === day,
    );

    if (dayIndex === -1 || !availability) return;

    slotsDraft = produce(slotsDraft, (draft) => {
      draft[dayIndex].enabled = true;
      draft[dayIndex].slots.push({
        start: setHours(setMinutes(new Date(), 0), startTime).toString(),
        end: setHours(setMinutes(new Date(), 0), endTime).toString(),
      });
    });
  });

  return slotsDraft;
}

export function setMinutes(date, min) {
  const newDate = new Date(date);
  newDate.setMinutes(min);
  return newDate;
}

export function setHours(date, hrs) {
  const newDate = new Date(date);
  newDate.setHours(hrs);
  return newDate;
}

export function hoursLessThan(hr) {
  const list = [];
  const tempArray = Array.from(Array(hr).keys());
  for (let index = 0; index < tempArray.length; index++) {
    list.push(setMinutes(setHours(new Date(), tempArray[index]), 0));
  }

  return list;
}

export function getTokenfromSession() {
  const access_token = JSON.parse(
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_KC_AUTHORITY}:${process.env.REACT_APP_KC_CLIENT_ID}`,
    ),
  )?.access_token;

  return access_token;
}

export function getMessageText(t, message, dateFormatter) {
  let messageText = message.messageText || "";
  if (message.localisationKey) {
    const options = { interpolation: { escapeValue: false } };
    if (message.interpolationParams) {
      for (const k in message.interpolationParams) {
        const param = message.interpolationParams[k];
        let value = param.value;
        if (param.type === "date" && dateFormatter) {
          value = dateFormatter(new Date(value), "dddd, MMMM D, YYYY, h:mm a.");
        }
        options[k] = value;
      }
    }
    messageText = t(message.localisationKey, options);
  }
  return messageText;
}

export function concatArrays(...arrays) {
  return [].concat(...arrays.filter((ar) => Array.isArray(ar)));
}

export function renderTextWithUrl(text) {
  const URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/gm;

  const EMAIL_REGEX = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gm;

  const detectedURLs = text.match(URL_REGEX);
  const detectedEmailIds = text.match(EMAIL_REGEX);

  let htmlText = text;
  new Set(concatArrays(detectedEmailIds, detectedURLs))?.forEach((link) => {
    let anchorTag;
    anchorTag = `<a href="${
      EMAIL_REGEX.test(link) ? "mailto:" : ""
    }${link}" target="_blank">${link}</a>`;
    htmlText = htmlText.replaceAll(link, anchorTag);
  });

  htmlText = htmlText.replaceAll("\n", "<br />"); // `text` could contain 'new line' character

  return <span dangerouslySetInnerHTML={{ __html: htmlText }} />;
}

export function showErrorPage(error, navigate) {
  const { status, statusText, errorCode, message } = error;
  navigate(PATH.SOMETHING_WENT_WRONG, {
    state: { status, statusText, errorCode, message },
  });
}

export const getUsername = (user) => {
  return user?.username
    ? user.username
    : user?.email
    ? user.email.substring(0, user.email.lastIndexOf("@"))
    : user?.id;
};

export const toCapitalCase = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(e.target.result);
    fileReader.onerror = () => reject("Error reading file");
    fileReader.readAsDataURL(file);
  });
};
