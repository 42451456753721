/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { setHours, setMinutes } from "utils/helpers";

export const OFC_PRIMARY_COLOR = "#446ABB";

export const CALENDAR_TABLE_COLUMN_KEYS = [
  {
    key: "requestedBy",
    label: "Requested By",
    align: "left",
    styles: {
      minWidth: "10rem",
    },
  },
  {
    key: "requestedTo",
    label: "To",
    align: "left",
    styles: {
      minWidth: "10rem",
    },
  },
  {
    key: "date",
    label: "Date",
    align: "left",
  },
  {
    key: "time",
    label: "Time",
    align: "left",
    styles: {
      minWidth: "7rem",
    },
  },
  {
    key: "status",
    label: "Status",
    align: "left",
  },
  {
    key: "action",
    label: " ",
    align: "left",
  },
];

export const MEETING_STATUS = {
  ACCEPTED: "accepted",
  REQUESTED: "scheduled",
  DECLINED: "declined",
  CANCELLED: "cancelled",
};

export const AVAILABILITY_KEYS = ["all", "am", "pm", "advanced"];

export const SKILL_TYPES = {
  USER: "user",
  ML: "ml",
  INTEGRATION: "integration",
};

export const DEBOUNCE_TIME = 500;

export const DAYS_OF_THE_WEEK = {
  MONDAY: "monday",
  TUESDAY: "tuesday",
  WEDNESDAY: "wednesday",
  THURSDAY: "thursday",
  FRIDAY: "friday",
  SATURDAY: "saturday",
  SUNDAY: "sunday",
};

export const SLOT_TIME_DURATION = 30;

export const DEFAULT_SLOT_START_TIME = "10:00";
export const DEFAULT_SLOT_END_TIME = "16:00";

export const DEFAULT_ADVANCED_AVAILABILITY = [
  {
    day: DAYS_OF_THE_WEEK.SUNDAY,
    isAvailable: false,
    startTime: DEFAULT_SLOT_START_TIME,
    endTime: DEFAULT_SLOT_END_TIME,
  },
  {
    day: DAYS_OF_THE_WEEK.MONDAY,
    isAvailable: true,
    startTime: DEFAULT_SLOT_START_TIME,
    endTime: DEFAULT_SLOT_END_TIME,
  },
  {
    day: DAYS_OF_THE_WEEK.TUESDAY,
    isAvailable: true,
    startTime: DEFAULT_SLOT_START_TIME,
    endTime: DEFAULT_SLOT_END_TIME,
  },
  {
    day: DAYS_OF_THE_WEEK.WEDNESDAY,
    isAvailable: true,
    startTime: DEFAULT_SLOT_START_TIME,
    endTime: DEFAULT_SLOT_END_TIME,
  },
  {
    day: DAYS_OF_THE_WEEK.THURSDAY,
    isAvailable: true,
    startTime: DEFAULT_SLOT_START_TIME,
    endTime: DEFAULT_SLOT_END_TIME,
  },
  {
    day: DAYS_OF_THE_WEEK.FRIDAY,
    isAvailable: true,
    startTime: DEFAULT_SLOT_START_TIME,
    endTime: DEFAULT_SLOT_END_TIME,
  },
  {
    day: DAYS_OF_THE_WEEK.SATURDAY,
    isAvailable: false,
    startTime: DEFAULT_SLOT_START_TIME,
    endTime: DEFAULT_SLOT_END_TIME,
  },
];

/**
 * @deprecated
 * The below DEFAULT_ADVANCED_AVAILABILITY_SETTINGS is deprecated and we no longer use it.
 */
export const DEFAULT_ADVANCED_AVAILABILITY_SETTINGS = [
  {
    day: DAYS_OF_THE_WEEK.MONDAY,
    enabled: false,
    slots: [
      {
        start: setHours(setMinutes(new Date(), 0), 14).toString(),
        end: setHours(setMinutes(new Date(), 0), 17).toString(),
      },
    ],
  },
  {
    day: DAYS_OF_THE_WEEK.TUESDAY,
    enabled: false,
    slots: [
      {
        start: setHours(setMinutes(new Date(), 0), 14).toString(),
        end: setHours(setMinutes(new Date(), 0), 17).toString(),
      },
    ],
  },
  {
    day: DAYS_OF_THE_WEEK.WEDNESDAY,
    enabled: false,
    slots: [
      {
        start: setHours(setMinutes(new Date(), 0), 14).toString(),
        end: setHours(setMinutes(new Date(), 0), 17).toString(),
      },
    ],
  },
  {
    day: DAYS_OF_THE_WEEK.THURSDAY,
    enabled: false,
    slots: [
      {
        start: setHours(setMinutes(new Date(), 0), 14).toString(),
        end: setHours(setMinutes(new Date(), 0), 17).toString(),
      },
    ],
  },
  {
    day: DAYS_OF_THE_WEEK.FRIDAY,
    enabled: false,
    slots: [
      {
        start: setHours(setMinutes(new Date(), 0), 14).toString(),
        end: setHours(setMinutes(new Date(), 0), 17).toString(),
      },
    ],
  },
  {
    day: DAYS_OF_THE_WEEK.SATURDAY,
    enabled: false,
    slots: [
      {
        start: setHours(setMinutes(new Date(), 0), 14).toString(),
        end: setHours(setMinutes(new Date(), 0), 17).toString(),
      },
    ],
  },
  {
    day: DAYS_OF_THE_WEEK.SUNDAY,
    enabled: false,
    slots: [
      {
        start: setHours(setMinutes(new Date(), 0), 14).toString(),
        end: setHours(setMinutes(new Date(), 0), 17).toString(),
      },
    ],
  },
];

export const DEFAULT_MEETINGS_PER_WEEK = 5;
export const MAX_MEETINGS_PER_WEEK = 80;
export const MIN_MEETINGS_PER_WEEK = 1;

export const AUTH_STATES = {
  IN_PROGRESS: "in_progress",
  CALLBACK: "callback",
  SUCCESS: "sucess",
  FAILED: "failed",
};

export const MEETING_FEEDBACK = {
  NO_FEEDBACK: "nf",
  USEFUL: "u",
  NOT_USEFUL: "nu",
  NO_SHOW: "ns",
};

export const MAX_CHARACTERS_FOR_INTRODUCTION_TEXT = 10000;
export const MAX_CHARACTERS_FOR_MESSAGE = 1000;
export const MIN_NUMBER_OF_SKILLS_REQUIRED = 3;

export const GENERAL_POPUP_DIALOG_TIMEOUT = 5000;
export const MOBILE_VIEWPORT_WIDTH_CUTOFF = 900; // md // change this when `md` value is changed in MUI

export const _30_MINUTES_IN_MILLISECONDS = 30 * 60 * 1000;
