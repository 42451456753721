/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messageText: "",
  messages: [],
  selectedConversation: null,
  selectedConversationParticipant: null,
  conversations: [],
  conversationsLimit: 10,
  conversationsTotal: 0,
  conversationsCurrentPage: 1,
  messagesLimit: 10,
  messagesTotal: 0,
  messagesCurrentPage: 1,
  participants: [],
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessageText: (state, action) => {
      state.messageText = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload.messages;
      state.messagesLimit = action.payload.limit;
      state.messagesTotal = action.payload.total;
      state.participants = action.payload.participants;
    },
    setConversations: (state, action) => {
      state.conversations = action.payload.conversations;
      state.conversationsLimit = action.payload.limit;
      state.conversationsTotal = action.payload.total;
    },
    setConversationsCurrentPage: (state, action) => {
      state.conversationsCurrentPage = action.payload;
    },
    setSeenConversation: (state, action) => {
      const index = state.conversations.findIndex(
        (con) => con.conversation_id === action.payload,
      );
      state.conversations[index].seen = true;
    },
    updateConversations: (state, action) => {
      state.conversations = action.payload;
    },
    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload.conversationId;
      state.selectedConversationParticipant = action.payload.otherParticipant;
    },
    addMessage: (state, action) => {
      const index = state.conversations.findIndex(
        (con) => con.conversation_id === action.payload.conversation_id,
      );
      state.conversations[index].message_text = action.payload.message_text;
      state.conversations[index].sent_date = action.payload.sent_date;
      state.conversations[index].seen = action.payload.seen;
      state.conversations[index].user_id = action.payload.user_id;
      state.messages.push(action.payload);
    },
    resetSelection: (state) => {
      state.messageText = "";
      state.messages = [];
      state.selectedConversation = null;
      state.selectedConversationParticipant = null;
      state.participants = [];
    },
  },
});

export const {
  setMessageText,
  setMessages,
  setConversations,
  setConversationsCurrentPage,
  updateConversations,
  setSelectedConversation,
  addMessage,
  setSeenConversation,
  resetSelection,
} = messageSlice.actions;

export default messageSlice.reducer;
