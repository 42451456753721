/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import axios from "axios";
import { ApiError } from "./apiError";

const { REACT_APP_BASE_URL, REACT_APP_API_VERSION } = process.env || {};

const baseURL = `${REACT_APP_BASE_URL}/${REACT_APP_API_VERSION}`;

const axiosInsance = axios.create({
  baseURL,
  timeout: 300000,
});

const getHeaders = (accessToken) => {
  return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
};

const processError = (authContext, error) => {
  console.log(error);
  const { response, message } = error;
  const { status, statusText, data } = response || {};
  const { errorCode, message: serverMessage } = data || {};
  if (status === 401) {
    authContext.signinRedirect();
  } else {
    throw new ApiError(status, statusText, errorCode, serverMessage || message);
  }
};

export const httpGet = async (authContext, url, params = {}) => {
  try {
    const response = await axiosInsance.get(url, {
      params,
      headers: getHeaders(authContext.accessToken),
    });
    const { data } = response;
    return data;
  } catch (error) {
    processError(authContext, error);
  }
};

export const httpPost = async (authContext, url, payload) => {
  try {
    const response = await axiosInsance.post(url, payload, {
      headers: getHeaders(authContext.accessToken),
    });

    const { data } = response;
    return data;
  } catch (error) {
    processError(authContext, error);
  }
};

export const httpPut = async (authContext, url, payload) => {
  try {
    const response = await axiosInsance.put(url, payload, {
      headers: getHeaders(authContext.accessToken),
    });

    const { data } = response;
    return data;
  } catch (error) {
    processError(authContext, error);
  }
};

export const httpDelete = async (authContext, url) => {
  try {
    const response = await axiosInsance.delete(url, {
      headers: getHeaders(authContext.accessToken),
    });

    const { data } = response;
    return data;
  } catch (error) {
    processError(authContext, error);
  }
};
