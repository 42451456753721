/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

const loadPath = `${process.env.REACT_APP_BASE_URL}/v1/i18n/locales/translations?lng={{lng}}&ns={{ns}}`;
const fallbackLng = process.env.REACT_APP_FALLBACK_LANG || "en";

const config = {
  fallbackLng,
  debug: false,
  detection: {
    order: ["localStorage", "cookie"],
    caches: ["localStorage", "cookie"],
  },
  backend: {
    loadPath,
  },
  react: {
    useSuspense: false,
  },
  saveMissing: false,
};

i18n.use(initReactI18next).use(LanguageDetector).use(HttpApi).init(config);

export default i18n;
