/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { configureStore } from "@reduxjs/toolkit";

import profileReducer from "reducers/profileSlice";
import skillsReducer from "reducers/skillSlice";
import authReducer from "reducers/authSlice";
import messageReducer from "reducers/messageSlice";

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    skills: skillsReducer,
    auth: authReducer,
    message: messageReducer,
  },
});
