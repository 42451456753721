/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React, { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { getUserProfile } from "api/profile";

const AuthContext = React.createContext();

const AuthContextProvider = ({ children }) => {
  const auth = useAuth();
  const { revokeTokens, removeUser, signinPopup, signinRedirect, user } = auth;
  const { access_token } = user || {};

  const [appUser, setAppUser] = useState();
  const [serverError, setServerError] = useState();

  useEffect(() => {
    const loadUser = async () => {
      try {
        const user = await getUserProfile(authContext);
        setAppUser(user);
      } catch (error) {
        setServerError(error);
        setAppUser(null);
      }
    };
    if (auth.isAuthenticated) {
      loadUser();
    } else {
      setAppUser(null);
    }
  }, [auth.isAuthenticated]);

  const reloadUserProfile = async () => {
    try {
      const user = await getUserProfile(authContext);
      setAppUser(user);
    } catch (error) {
      setServerError(error);
    }
  };

  const authContext = {
    isLoading: auth.isLoading,
    isAuthenticated: auth.isAuthenticated,
    user: appUser,
    error: auth.error,
    serverError,
    accessToken: access_token,
    reloadUserProfile: async () => {
      await reloadUserProfile();
    },
    signinPopup: async () => {
      await removeUser();
      await signinPopup();
    },
    signinRedirect: async () => {
      await removeUser();
      await signinRedirect();
    },
    logout: async () => {
      if (auth.isAuthenticated) {
        await revokeTokens(["access_token", "refresh_token"]);
        await removeUser();
      }
    },
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  }
  return context;
}

export { AuthContextProvider, useAuthContext };
