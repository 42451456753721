/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import CircularProgress from "@mui/material/CircularProgress";

import Grid from "components/bits/Grid";
import Typography from "components/bits/Typography";

function Loader({ marginTop = 30, marginLeft = 3, ...restProps }) {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <CircularProgress
          color="primary"
          sx={{ marginTop, marginLeft, ...restProps }}
        />
        <Typography variant="h3" component="div" sx={{ pt: 2 }}>
          Loading...
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Loader;
