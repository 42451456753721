/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { httpGet, httpPost, httpPut } from "api/http.service";

export const getUserProfile = async (authContext) => {
  const response = await httpGet(authContext, "/profile");
  return response;
};

export const updateUserProfile = async (authContext, data) => {
  const response = await httpPut(authContext, "/profile", data);
  return response;
};

export const finishOnboarding = async (authContext, data) => {
  const response = await httpPost(authContext, "/profile/onboarding", data);
  return response;
};

export const skipOnboarding = async (authContext, data) => {
  const response = await httpPost(
    authContext,
    "/profile/onboarding/skip",
    data,
  );
  return response;
};

export const getPeopleProfile = async (authContext, username) => {
  const response = await httpGet(authContext, `/profile/user/${username}`);
  return response;
};
