/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React, { useState, useEffect } from "react";
import { getUnreadMessages } from "api/conversation";
import { useAuthContext } from "context/authContext";
import { getNotRespondedMeetings } from "api/meeting";
import { fetchEventSource } from "@microsoft/fetch-event-source";

const SseContext = React.createContext();

class SseRetriableError extends Error {}
class SseFatalError extends Error {}
const { REACT_APP_BASE_URL, REACT_APP_API_VERSION } = process.env || {};
const baseURL = `${REACT_APP_BASE_URL}/${REACT_APP_API_VERSION}`;

const SseContextProvider = ({ children }) => {
  const authContext = useAuthContext();

  const [loadInitialState, setLoadInitialState] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [newMeetingRequests, setNewMeetingRequests] = useState([]);
  const [sseEvent, setSseEvent] = useState();

  useEffect(() => {
    const getInitialState = async () => {
      try {
        const p1 = loadUndeadMessages();
        const p2 = loadNotRespondedMeetings();
        await Promise.all([p1, p2]);
      } catch (error) {
        console.log(error);
      }
      setLoadInitialState(false);
    };
    const loadUndeadMessages = async () => {
      try {
        const unreadMessages = await getUnreadMessages(authContext);
        setUnreadMessages(unreadMessages);
      } catch (error) {
        console.log(error);
      }
    };
    const loadNotRespondedMeetings = async () => {
      try {
        const meetings = await getNotRespondedMeetings(authContext);
        setNewMeetingRequests(meetings);
      } catch (error) {
        console.log(error);
      }
    };
    if (authContext.isAuthenticated && loadInitialState) {
      getInitialState();
    }
  }, [authContext.isAuthenticated, loadInitialState]);

  useEffect(() => {
    if (sseEvent) {
      const { sendToUserId } = sseEvent;
      if (Number(sendToUserId) !== authContext.user.id) {
        return;
      }
      setLoadInitialState(true);
    }
  }, [sseEvent]);

  useEffect(() => {
    const createEventSource = async () => {
      await fetchEventSource(
        `${baseURL}/notifications/sse?accessToken=${authContext.accessToken}`,
        {
          openWhenHidden: true,
          onopen(response) {
            if (response.ok && response.status === 200) {
              console.log("Connection made ", response);
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              throw new SseFatalError();
            } else {
              throw new SseRetriableError();
            }
          },
          onmessage(event) {
            if (event.event === "FatalError") {
              throw new SseFatalError(event.data);
            }
            const eventData = JSON.parse(event.data);
            setSseEvent(eventData);
          },
          onclose() {
            throw new SseRetriableError();
          },
          onerror(err) {
            if (err instanceof SseFatalError) {
              throw err;
            } else {
              return 1000;
            }
          },
        },
      );
    };
    createEventSource();
  }, [authContext.accessToken]);

  return (
    <SseContext.Provider
      value={{
        unreadMessages,
        newMeetingRequests,
        sseEvent,
      }}
    >
      {children}
    </SseContext.Provider>
  );
};

function useSseContext() {
  const context = React.useContext(SseContext);
  if (context === undefined) {
    throw new Error("useSseContext must be used within a SseContextProvider");
  }
  return context;
}

export { SseContextProvider, useSseContext };
