/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useState } from "react";

import Modal from "components/bits/Modal";
import Typography from "components/bits/Typography";
import Grid from "components/bits/Grid";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "components/bits/Button";
import { useModalContext } from "context/modalContext";

const MessageModal = () => {
  const { t } = useTranslation();
  const { open, modalParams = {} } = useModalContext();
  const { message, yesCallback, noCallback, closeModal } = modalParams;

  const [loading, setLoading] = useState(false);

  const handleClickYes = async () => {
    setLoading(true);
    await yesCallback();
    setLoading(false);
  };

  return (
    <Modal open={open} setOpen={closeModal} data-test-id="modal">
      <Typography
        sx={{ textAlign: "center", mt: 1, mb: 4 }}
        data-test-id="modal-message"
      >
        {message}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {noCallback && (
            <LoadingButton
              onClick={noCallback}
              color="primary"
              type="primary"
              data-test-id="modal-cancel-button"
            >
              {t("common.no")}
            </LoadingButton>
          )}
        </Grid>
        <Grid item xs={6}>
          {yesCallback && (
            <LoadingButton
              loading={loading}
              onClick={handleClickYes}
              color="error"
              type="primary"
              data-test-id="modal-confirm-button"
            >
              {t("common.yes")}
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default MessageModal;
