/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

export class ApiError extends Error {
  status;
  statusText;
  errorCode;
  message;
  constructor(status, statusText, errorCode, message) {
    super();
    this.status = status;
    this.statusText = statusText;
    this.errorCode = errorCode;
    this.message = message;
  }
}
