/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Button, CircularProgress } from "@mui/material";

const LoadingButton = (props) => {
  return (
    <Button {...props}>
      {props.loading ? (
        <CircularProgress size={28} sx={{ color: "white" }} />
      ) : (
        props.children
      )}
    </Button>
  );
};

export default LoadingButton;
