/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { httpGet, httpPost, httpPut } from "api/http.service";

export const getConversations = async (authContext, targetUserId) => {
  const response = await httpGet(authContext, "/conversations", {
    targetUserId,
  });
  return response;
};

export const getUnreadMessages = async (authContext) => {
  const response = await httpGet(authContext, "/conversations/messages/unread");
  return response;
};

export const getConversationMessages = async (
  authContext,
  conversationId,
  fromTime,
) => {
  const response = await httpGet(
    authContext,
    `/conversations/${conversationId}/messages`,
    { fromTime },
  );
  return response;
};

export const createConversation = async (authContext, conversation) => {
  const response = await httpPost(authContext, `/conversations`, conversation);
  return response;
};

export const sendMessage = async (authContext, conversationId, message) => {
  const response = await httpPost(
    authContext,
    `/conversations/${conversationId}/message`,
    message,
  );
  return response;
};

export const updateMessageSeen = async (authContext, conversationId) => {
  const response = await httpPut(
    authContext,
    `/conversations/${conversationId}/messages/status/seen`,
  );
  return response;
};
