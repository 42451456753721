/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searching: false,
  searchText: "",
  skills: [],
};

export const skillSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {
    setSkills: (state, action) => {
      state.searching = false;
      state.skills = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setSearching: (state) => {
      state.searching = true;
      state.skills = [];
    },
    removeSkills: (state) => {
      state.skills = [];
    },
  },
});

export const { setSkills, setSearching, setSearchText, removeSkills } =
  skillSlice.actions;

export default skillSlice.reducer;
