/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useState, createContext, useContext } from "react";
import MessageModal from "components/items/MessageModal";

export const ModalContext = createContext();

const ModalContextProvider = (props) => {
  const [open, setOpen] = useState(false);

  const [modalParams, setModalParams] = useState({
    message: "",
    yesCallback: () => setOpen(false),
    noCallback: () => setOpen(false),
  });

  const closeModal = () => {
    setOpen(false);
  };

  const showConfirmation = (message, yesCallback = null, noCallback = null) => {
    setModalParams({
      message,
      yesCallback: async () => {
        if (yesCallback) {
          await yesCallback();
        }
        setOpen(false);
      },
      noCallback: async () => {
        if (noCallback) {
          await noCallback();
        }
        setOpen(false);
      },
    });
    setOpen(true);
  };

  return (
    <ModalContext.Provider
      value={{
        open,
        modalParams,
        showConfirmation,
        closeModal,
      }}
    >
      {props.children}
      <MessageModal />
    </ModalContext.Provider>
  );
};

function useModalContext() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error(
      "useModalContext must be used within a ModalContextProvider",
    );
  }
  return context;
}

export { ModalContextProvider, useModalContext };
