/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { createSlice } from "@reduxjs/toolkit";

import { AUTH_STATES } from "constants/app.constants";

const initialState = {
  authState: AUTH_STATES.IN_PROGRESS,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthStatusCallback: (state) => {
      state.authState = AUTH_STATES.CALLBACK;
    },
    setLoggedIn: (state) => {
      state.authState = AUTH_STATES.SUCCESS;
    },
    setLoggedOut: (state) => {
      state.authState = AUTH_STATES.IN_PROGRESS;
    },
  },
});

export const { setLoggedIn, setLoggedOut, setAuthStatusCallback } =
  authSlice.actions;

export default authSlice.reducer;
