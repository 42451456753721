/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import * as React from "react";
import Box from "../Box";
import MUIModal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function Modal({ children, open, setOpen }) {
  const handleClose = () => setOpen(false);

  return (
    <MUIModal open={open} onClose={handleClose}>
      <Box sx={style}>{children}</Box>
    </MUIModal>
  );
}
