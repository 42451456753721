/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_ADVANCED_AVAILABILITY_SETTINGS,
  DEFAULT_MEETINGS_PER_WEEK,
  SKILL_TYPES,
} from "constants/app.constants";
import { convertToUIFormat } from "utils/helpers";

const initialState = {
  id: null,
  name: "",
  email: "",
  introductionText: "",
  skills: [],
  skillsFromIntegration: [],
  skillsFromML: [],
  availability: null,
  jobTitle: "",
  locationName: "",
  timezone: "",
  profilePic: null,
  userAdvancedAvailability: [],
  meetingsRequests: [],
  unreadMessages: [],
  maxMeetingsPerWeek: DEFAULT_MEETINGS_PER_WEEK,
  onboardingSeen: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileDetails: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name || "";
      state.email = action.payload.email || "";
      state.introductionText = action.payload.introduction_text || "";
      state.skills = action.payload.skills.filter(
        (skill) => skill.skill_type === SKILL_TYPES.USER,
      );
      state.skillsFromIntegration = action.payload.skills.filter(
        (skill) => skill.skill_type === SKILL_TYPES.INTEGRATION,
      );
      state.skillsFromML = action.payload.skills.filter(
        (skill) => skill.skill_type === SKILL_TYPES.ML,
      );
      state.availability = action.payload.availability;
      state.userAdvancedAvailability = convertToUIFormat(
        action.payload.user_advanced_availability || [],
      );
      state.jobTitle = action.payload.job_title;
      state.locationName = action.payload.location_name;
      state.profilePic = action.payload.profile_pic;
      state.maxMeetingsPerWeek = action.payload.number_of_meetings;
      state.onboardingSeen = action.payload.onboarding_complete;
    },
    setUnreadMessages: (state, action) => {
      state.unreadMessages = action.payload || [];
    },
    setMeetingRequests: (state, action) => {
      state.meetingsRequests = action.payload || [];
    },
    setIntroductionText: (state, action) => {
      state.introductionText = action.payload;
    },
    setAvailability: (state, action) => {
      state.availability = action.payload;
    },
    deleteSkill: (state, action) => {
      if (action.payload.type === SKILL_TYPES.INTEGRATION) {
        state.skillsFromIntegration.splice(action.payload.index, 1);
      } else {
        state.skills.splice(action.payload.index, 1);
      }
    },
    deleteSkillFromML: (state, action) => {
      state.skillsFromML.splice(action.payload, 1);
    },
    setProfilePic: (state, action) => {
      state.profilePic = action.payload;
    },
    selectSkills: (state, action) => {
      state.skills.push({
        skill_id: action.payload.id,
        skill_name: action.payload.skill_name,
      });
    },
    setMaxMeetingsPerWeek: (state, action) => {
      state.maxMeetingsPerWeek = action.payload;
    },
    setUserAdvancedAvailability: (state, action) => {
      state.userAdvancedAvailability = action.payload;
    },
    toggleAvailability: (state, action) => {
      state.userAdvancedAvailability[action.payload].enabled =
        !state.userAdvancedAvailability[action.payload].enabled;
    },
    addTimeSlot: (state, action) => {
      state.userAdvancedAvailability[action.payload].slots.push(
        DEFAULT_ADVANCED_AVAILABILITY_SETTINGS[action.payload].slots[0],
      );
    },
    deleteTimeSlot: (state, action) => {
      if (
        state.userAdvancedAvailability[action.payload.dayIndex].slots.length ===
        1
      ) {
        state.userAdvancedAvailability[action.payload.dayIndex].enabled = false;
      } else {
        state.userAdvancedAvailability[action.payload.dayIndex].slots.splice(
          action.payload.timeSlotIndex,
          1,
        );
      }
    },
    changeTime: (state, action) => {
      const { key, date, dayIndex, timeSlotIndex } = action.payload;
      state.userAdvancedAvailability[dayIndex].slots[timeSlotIndex][key] = date;
    },
  },
});

export const {
  setProfileDetails,
  setIntroductionText,
  setUnreadMessages,
  setMeetingRequests,
  setAvailability,
  deleteSkill,
  deleteSkillFromML,
  setProfilePic,
  selectSkills,
  setSearchText,
  setMaxMeetingsPerWeek,
  setUserAdvancedAvailability,
  toggleAvailability,
  addTimeSlot,
  deleteTimeSlot,
  changeTime,
} = profileSlice.actions;

export default profileSlice.reducer;
