/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SseContextProvider } from "context/sseContext";
import Loader from "components/bits/Loader";
import { PATH } from "constants/path.constants";
import { useAuthContext } from "context/authContext";

const PageNotFound = lazy(() => import("containers/PageNotFound"));
const SomethingWentWrong = lazy(() => import("containers/SomethingWentWrong"));
const Onboarding = React.lazy(() => import("containers/Onboarding"));
const Layout = React.lazy(() => import("containers/Layout"));
const EditProfile = React.lazy(() => import("containers/EditProfile"));
const Calendar = React.lazy(() => import("containers/Calendar"));
const SearchResults = React.lazy(() => import("containers/SearchResults"));
const Conversations = React.lazy(() => import("containers/Conversations"));
const ProfileRouter = React.lazy(() => import("routes/ProfileRouter"));

function AppRoutes() {
  const authContext = useAuthContext();

  const { t, i18n } = useTranslation();

  const { isLoading, isAuthenticated, user, serverError } = authContext;

  useEffect(() => {
    if (authContext.error) {
      authContext.signinRedirect();
    }
  }, [authContext, authContext.error]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      authContext.signinRedirect();
    }
  }, [authContext, isAuthenticated, isLoading]);

  if (isLoading || (!user && !serverError)) {
    return <Loader />;
  }

  const getUsername = () => {
    return user?.username
      ? user.username
      : user?.email
      ? user.email.substring(0, user.email.lastIndexOf("@"))
      : user?.id;
  };

  return (
    <SseContextProvider>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path={PATH.ONBOARDING}
            element={<Onboarding t={t} i18n={i18n} />}
          />
          <Route
            path={PATH.SOMETHING_WENT_WRONG}
            element={<SomethingWentWrong t={t} i18n={i18n} />}
          />
          <Route path={PATH.HOME} element={<Layout t={t} i18n={i18n} />}>
            <Route
              index
              element={
                <Navigate
                  to={PATH.USER_PROFILE.replace(":username", getUsername())}
                  replace
                />
              }
            />
            <Route
              path={PATH.EDIT_PROFILE}
              element={<EditProfile t={t} i18n={i18n} />}
            />
            <Route
              path={PATH.CALENDAR}
              element={<Calendar t={t} i18n={i18n} />}
            />
            <Route
              path={PATH.SEARCH_RESULTS}
              element={<SearchResults t={t} i18n={i18n} />}
            />
            <Route
              path={PATH.USER_PROFILE}
              element={<ProfileRouter t={t} i18n={i18n} />}
            />
            <Route
              path={PATH.MESSAGES}
              element={<Conversations t={t} i18n={i18n} />}
            />
          </Route>
          <Route path="*" element={<PageNotFound t={t} i18n={i18n} />} />
        </Routes>
      </Suspense>
    </SseContextProvider>
  );
}

export default AppRoutes;
