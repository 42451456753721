/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

export const PATH = {
  HOME: "/",
  ONBOARDING: "/onboarding",
  USER_PROFILE: "/people/:username",
  EDIT_PROFILE: "/editProfile",
  SETTINGS: "/settings",
  SEARCH_RESULTS: "/search",
  CALENDAR: "/calendar",
  MESSAGES: "/messages",
  SOMETHING_WENT_WRONG: "/error",
};
