/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "react-oidc-context";

import Routes from "routes";
import theme from "styles/theme";
import { store } from "store";

import "./i18n";
import { AuthContextProvider } from "context/authContext";
import { ModalContextProvider } from "context/modalContext";
import { TimeZoneContextProvider } from "context/timeZoneContext";

const {
  REACT_APP_KC_AUTHORITY,
  REACT_APP_KC_CLIENT_ID,
  REACT_APP_KC_REDIRECT_URI,
} = process.env || {};

const onSigninCallback = (_user) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  authority: REACT_APP_KC_AUTHORITY,
  client_id: REACT_APP_KC_CLIENT_ID,
  redirect_uri: REACT_APP_KC_REDIRECT_URI,
  onSigninCallback,
};

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider {...oidcConfig}>
            <AuthContextProvider>
              <TimeZoneContextProvider>
                <ModalContextProvider>
                  <Routes />
                </ModalContextProvider>
              </TimeZoneContextProvider>
            </AuthContextProvider>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
