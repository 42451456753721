/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { httpDelete, httpGet, httpPost, httpPut } from "api/http.service";

export const scheduleMeeting = async (authContext, userId, data) => {
  const response = await httpPost(authContext, `/meetings/${userId}`, data);
  return response;
};

export const acceptMeeting = async (authContext, meetingId) => {
  const response = await httpPut(authContext, `/meetings/${meetingId}/accept`);
  return response;
};

export const rejectMeeting = async (authContext, meetingId) => {
  const response = await httpPut(authContext, `/meetings/${meetingId}/decline`);
  return response;
};

export const cancelMeeting = async (authContext, meetingId) => {
  const response = await httpDelete(authContext, `/meetings/${meetingId}`);
  return response;
};

export const getMeetings = async (authContext) => {
  const response = await httpGet(authContext, `/meetings`);
  return response;
};

export const getNotRespondedMeetings = async (authContext) => {
  const response = await httpGet(authContext, "/meetings/notresponded");
  return response;
};

export const getMeetingsForFeedback = async (authContext) => {
  const response = await httpGet(authContext, `/meetings/forFeedback`);
  return response;
};

export const addMeetingFeedback = async (authContext, meetingId, body) => {
  const response = await httpPost(
    authContext,
    `/meetings/feedback/${meetingId}`,
    body,
  );
  return response;
};

export const getActiveMeetingStatus = async (authContext, userId) => {
  const response = await httpGet(
    authContext,
    `/meetings/${userId}/activeMeetingStatus`,
  );
  return response;
};

export const getAvailabilityDays = async (authContext, userId, body) => {
  const response = await httpGet(
    authContext,
    `/meetings/availability/days/${userId}`,
    body,
  );
  return response;
};

export const getAvailabilityTimes = async (authContext, userId, day) => {
  const response = await httpGet(
    authContext,
    `/meetings/availability/times/${userId}`,
    { day },
  );
  return response;
};
