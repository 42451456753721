/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    common: {
      background: "#F6F6F6",
      card: {
        borderColor: "#E8E8E8",
        lightBackground: "#FAFAFA",
      },
      input: {
        borderColor: "#A8A8A8",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "6px 12px",
      },
      error: {
        color: "#DA3A3A",
        main: "#DA3A3A",
      },
      button: {
        colors: {
          primary: "#446ABB",
          secondary: "#F8DA5B",
          error: "#DA3A3A",
        },
      },
    },
    primary: {
      main: "#446ABB",
    },
    secondary: {
      main: "#F8DA5B",
    },
    disabled: {
      backgroundColor: "#A8A8A8",
      textColor: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  shape: {
    card: {
      borderRadius: 8,
    },
  },
});

theme.components = {
  MuiAppBar: {
    styleOverrides: {
      root: { backgroundColor: theme.palette.common.white, boxShadow: "none" },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingTop: "3rem",
        paddingBottom: "3rem",
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: "30px",
        fontWeight: 700,
      },
      h2: {
        fontSize: "30px",
        fontWeight: 600,
      },
      h3: {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "21px",
        lineHeight: "25px",
        color: theme.palette.common.black,
      },
      h4: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "19px",
        color: theme.palette.common.black,
      },
      h5: {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        color: theme.palette.common.black,
      },
      h6: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "19px",
        color: theme.palette.common.black,
      },
      subtitle1: {
        color: "#A8A8A8",
        fontWeight: 400,
        fontSize: "21px",
        lineHeight: "25px",
        letterSpacing: "0.75px",
      },
      subtitle2: {
        color: theme.palette.common.white,
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "24px",
        letterSpacing: "0.75px",
      },
      body1: {
        color: theme.palette.common.black,
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
      },
      body1Bold: {
        color: theme.palette.common.black,
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
      },
      body2: {
        color: theme.palette.common.input.borderColor,
        fontWeight: 400,
        fontSize: "12px",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
        color: theme.palette.common.white,
        padding: "4px 9px",
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
      },
      deleteIcon: {
        color: "inherit",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        backgroundColor: theme.palette.common.background,
        borderRadius: theme.shape.card.borderRadius,
        borderWidth: theme.palette.common.input.borderWidth,
        borderStyle: theme.palette.common.input.borderStyle,
        borderColor: theme.palette.common.card.borderColor,
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: "20px 25px 50px 25px !important",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: false,
      variant: "outlined",
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const style = {
          backgroundColor: theme.palette.primary.main,
          borderRadius: 8,
          color: theme.palette.common.white,
          padding: "0.7rem 70px",
          fontSize: "1rem",
          fontWeight: 600,
          textTransform: "unset",
          "&:disabled": {
            backgroundColor: theme.palette.disabled.backgroundColor,
            color: theme.palette.disabled.textColor,
          },
        };

        if (Boolean(ownerState.color)) {
          style.backgroundColor =
            theme.palette.common.button.colors[ownerState.color];
        }

        if (ownerState.type === "primary") {
          return {
            ...style,
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.common.white,
            },
          };
        }

        if (ownerState.type === "secondary") {
          return {
            ...style,
            padding: "0.6rem 70px",
            color: theme.palette.common.black,
            border: "none",
            "&:hover": {
              border: "none",
              backgroundColor: theme.palette.secondary.main,
            },
          };
        }
        if (ownerState.type === "link") {
          return {
            ...style,
            backgroundColor: "transparent",
            padding: 0,
            fontWeight: 500,
            border: "none",
            color: theme.palette.primary.main,
            "&:hover": {
              border: "none",
              backgroundColor: "transparent",
            },
          };
        }

        if (ownerState.type === "table-action") {
          return {
            ...style,
            padding: 0,
            fontSize: "1rem",
            borderRadius: 8,
            fontWeight: 700,
            textAlign: "center",
            minWidth: "6rem",
            "&:hover": {
              backgroundColor: style.backgroundColor,
            },
          };
        }

        if (ownerState.type === "message-action") {
          return {
            ...style,
            padding: "4px 0px",
            fontSize: "1rem",
            borderRadius: 8,
            fontWeight: 700,
            textAlign: "center",
            minWidth: "6rem",
            "&:hover": {
              backgroundColor: style.backgroundColor,
            },
          };
        }
        return style;
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: "1rem",
        backgroundColor: theme.palette.common.background,
        borderRadius: theme.shape.borderRadius,
        borderWidth: theme.palette.common.input.borderWidth,
        borderStyle: theme.palette.common.input.borderStyle,
        borderColor: theme.palette.common.input.borderColor,
        padding: theme.palette.common.input.padding,
        "&.Mui-error": {
          borderColor: "red",
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        fontSize: "1rem",
        backgroundColor: theme.palette.common.background,
        borderRadius: theme.shape.borderRadius,
        borderWidth: theme.palette.common.input.borderWidth,
        borderStyle: theme.palette.common.input.borderStyle,
        borderColor: theme.palette.common.input.borderColor,
        padding: theme.palette.common.input.padding,
        "&.Mui-error": {
          borderColor: "red",
        },
        "&.MuiOutlinedInput-root .MuiAutocomplete-input": {
          border: 0,
          padding: "6px 0 6px 0",
        },
        "&.MuiOutlinedInput-notchedOutline": {
          color: "red",
          border: 0,
          padding: theme.palette.common.input.padding,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
          padding: theme.palette.common.input.padding,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: 0,
          padding: theme.palette.common.input.padding,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        border: 0,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.black,
        marginBottom: 4,
      },
      asterisk: {
        color: theme.palette.common.error.color,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: theme.palette.common.error.color,
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        height: 50,
        width: 75,
      },
      switchBase: {
        top: 6,
        left: 6,
        color: theme.palette.primary.main,
        "&.Mui-checked": {
          color: theme.palette.common.white,
          transform: "translateX(25px)",
          "&+.MuiSwitch-track": {
            backgroundColor: theme.palette.primary.main,
            border: `2px solid ${theme.palette.primary.main}`,
            opacity: 1,
          },
        },
        "&hover.Mui-checked": {
          backgroundColor: "transparent",
        },
        "&.Mui-disabled": {
          "&+.MuiSwitch-track": {
            opacity: 0.6,
          },
        },
      },
      track: {
        borderRadius: 40,
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.common.input.borderColor}`,
        opacity: 1,
      },
    },
  },
  MuiBadge: {
    defaultProps: {
      variant: "dot",
      color: "primary",
      badgeContent: "",
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      overlap: "circular",
    },
    styleOverrides: {
      badge: {
        transform: "scale(2) translate(-55%, -50%)",
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        "& .MuiTableCell-root": {
          borderColor: theme.palette.common.black,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    },
  },
};

export default theme;
